import React from 'react';
import PageLayout from '@medefer/page-layout';
import './Footer.css';
import LinkButton from '@medefer/link-button';
import { PrivacyPolicyUrl } from '../../constants/environmentVariables';

function Footer() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <footer>
      <PageLayout className="">
        <div>
          <LinkButton
            className="mb-2"
            label="Privacy Policy"
            onClick={() => openInNewTab(PrivacyPolicyUrl)}
          />
        </div>
        <div className="footer-content">
          <div className="footer-content-first-item">
            <span>
              {`© 2013 - ${new Date().getFullYear()} Medefer Limited`}
            </span>
            <span>
              All rights reserved.
            </span>
          </div>
          <div>
            <span>
              19 Eastbourne Terrace, Finsbury Park, London W2 6LG
            </span>
            <span>
              Registered in England and Wales, company no. 08770754
            </span>
          </div>
        </div>
      </PageLayout>
    </footer>
  );
}

export default Footer;
